const enTranslation = {
  NewUser: 'New User',
  Search: 'Search',
  All: 'All',
  News: 'News',
  Overview: 'Overview',
  Header_SampleSearch: 'Sample Search',
  Header_SampleDetails: 'Sample Details',
  Header_SampleInfo: 'Sample Info',
  Header_JobInfo: 'Job Info',
  Header_InPrep: 'IN PREP',
  Header_InTransit: 'IN TRANSIT',
  Header_InAnalysis: 'IN ANALYSIS',
  Header_JobAge: 'JOB AGE',
  Header_SampleAge: 'SAMPLE AGE',
  Header_In: 'IN',
  Header_Out: 'OUT',
  Header_RecentNews: 'Recent News',
  Header_JobProgress: 'Job Progress',
  Header_UserSearch: 'Users',
  Header_Roles: 'Roles',
  Header_NewsSearch: 'News Search',
  Header_DocumentSearch: 'Document Search',
  Header_Document: 'Document',
  Header_AddDocument: 'Add New Document',
  Header_EditDocument: 'Edit Document',
  Header_News: 'News',
  Header_General: 'General',
  Header_Access: 'Access',
  Header_Info: 'Info',
  Header_RoleAndAccess: 'Role And Access',
  Header_RequestSearch: 'Request Search',
  Header_Documents: 'Documents',
  Header_ReportSearch: 'Report Search',
  Header_RootCauseSearch: 'Root Cause Search',
  Header_AddRootCause: 'Add Root Cause',
  Header_EditRootCause: 'Edit Root Cause',
  Header_Locations: 'Locations',
  Header_AddLocation: 'Add Location',
  Header_EditLocation: 'Edit Location',
  Header_RegionSearch: 'Region Search',
  Header_Regions: 'Regions',
  Header_AddRegion: 'Add Region',
  Header_EditRegion: 'Edit Region',
  Header_IssueTypes: 'Issue Types',
  Header_AddIssueType: 'Add Issue Type',
  Header_EditIssueType: 'Edit Issue Type',
  Header_Countries: 'Countries',
  Header_AddCountry: 'Add Countries',
  Header_EditCountry: 'Edit Countries',
  Header_RootCauseCategories: 'Root Cause Categories',
  Header_AddRootCauseCategorySearch: 'Add Root Cause Category Search',
  Header_EditRootCauseCategory: 'Edit Root Cause Category',
  Header_AddRootCauseCategory: 'Add Root Cause Category',
  Header_RequestCategorySourceSystemMapping: 'Request Category Source',
  Header_AddRequestCategorySourceSystemMapping:
        'Add Request Category Source',
  Header_EditRequestCategorySourceSystemMapping:
        'Edit Request Category Source',
  Header_EmailSearch: 'Email Search',
  Header_EditRequestCategory: 'Edit Request Category',
  Header_AddMap: 'Add Mapping',
  Header_EditMap: 'Edit Mapping',
  Header_AddRiskRanking: 'Add Risk Ranking',
  Header_EditRiskRanking: 'Edit Risk Ranking',
  Header_EditRequest: 'Edit Request',
  Header_ShowingRequests: 'Showing request(s)',
  Header_ShowingNRequests: 'Showing {{n}} request(s)',
  Header_ShowingNActions: 'Showing {{n}} actions(s)',
  Header_ShowingNRecords: 'Showing {{n}} records(s)',
  Header_RequestFields: 'Request Fields',
  AppTitlePrefix: 'iEval',

  Nav_Menu_Home: 'Home',
  Nav_Menu_Dashboard: 'Dashboard',
  Nav_Menu_Requests: 'Requests',
  Nav_Menu_Create_Request: 'Create Requests',
  Nav_Menu_Sent_Emails: 'Sent Emails',
  Nav_Menu_Verification: 'Verifications',
  Nav_Menu_Ation_Center: 'Action Center',
  Nav_Menu_Actions: 'Actions',
  Nav_Menu_List: 'List',
  Nav_Menu_My_Actions: 'My Actions',
  Nav_Menu_Master_Data: 'Master Data',
  Nav_Menu_Companies: 'Companies',
  Nav_Menu_Countries: 'Countries',
  Nav_Menu_Issue_Types: 'Issue Types',
  Nav_Menu_Locations: 'Locations',
  Nav_Menu_Probability_Rankings: 'Probability Rankings',
  Nav_Menu_Regions: 'Regions',
  Nav_Menu_Request_Categories: 'Request Categories',
  Nav_Menu_Request_Category_Source: 'Request Category Source',
  Nav_Menu_Risk_Rankings: 'Risk Rankings',
  Nav_Menu_Root_Causes: 'Root Causes',
  Nav_Menu_Root_Cause_Categories: 'Root Cause Categories',
  Nav_Menu_Severity_Rankings: 'Severity Rankings',
  Nav_Menu_Administrator: 'Administrator',
  Nav_Menu_Request_Workflow: 'Request Workflow',
  Nav_Menu_Roles: 'Roles',
  Nav_Menu_Users: 'Users',
  Nav_Menu_ObjectConfiguration: 'Object Configuration',

  Header_AddRole: 'Add Role',
  Header_EditRole: 'Edit Role',
  Header_RootCauses: 'Root Causes',
  Header_Profile: 'Profile',
  Breadcrumb_ObjectConfigurations: 'Object Configurations',
  Breadcrumb_Regions: 'Regions',
  Breadcrumb_UserManagement: 'User Management',
  Breadcrumb_EmailSearch: 'Email Search',
  Breadcrumb_RiskRanking: 'Risk Rankings',
  Breadcrumb_Home: 'Home',
  Breadcrumb_IssueTypes: 'Issue Types',
  Breadcrumb_RequestCategorySourceSystemMapping: 'Request Category Source',
  Breadcrumb_RequestManagement: 'Request Management ',
  Breadcrumb_SentEmails: 'Sent Emails',
  Header_SentEmails: 'Sent Emails',
  Breadcrumb_MyActions: 'My Actions',
  Breadcrumb_Roles: 'Roles',
  Breadcrumb_RootCauses: 'Root Causes',
  Breadcrumb_Users: 'Users',

  Text_DeleteChecklist: 'Delete Checklist',
  Text_ListName: 'List Name',
  Text_AddRegion: 'Add Region',
  Text_EditRegion: 'Edit Region',
  Text_AddUser: 'Add User',
  Text_EditUser: 'Edit User',
  Text_RegionID: 'Region ID',
  Text_RegionName: 'Region Name',
  Text_Region: 'Region',
  Text_Countries: 'Countries',
  Text_AddIssueType: 'Add Issue Type',
  Text_EditIssueType: 'Edit Issue Type',
  Text_IssueTypeID: 'Issue Type ID',
  Text_IssueType: 'Issue Type',
  Text_Description: 'Description',
  Text_Reference: 'Reference',
  Text_Active: 'Active',
  Text_UserName: 'Username',
  Text_FirstName: 'First Name',
  Text_LastName: 'Last Name',
  Text_CreatedBy: 'Created By',
  Text_CreatedOn: 'Created On',
  Text_UpdatedBy: 'Updated By',
  Text_UpdatedOn: 'Updated On',
  Text_SentOn: 'Sent On',
  Text_CountryName: 'Country Name',
  Text_IssueName: 'Issue Name',
  Text_IssueDescription: 'Issue Description',
  Text_IssueCategory: 'Issue Category',
  Text_DueDays: 'Due Days',
  Text_SourceFilter: 'Source Filter',
  Text_RoleName: 'Role Name',
  Text_Status: 'Status',
  Text_Request: 'Request',
  Text_RequestTitle: 'Request Title',
  Text_EmailSubject: 'Email Subject',
  Text_LocationAbbreviation: 'Location Abbreviation',
  Text_ID: 'ID',
  Text_RequestManager: 'Request Manager',
  Text_AddCompanies: 'Add Companies',
  Text_EditCompanies: 'Edit Companies',
  Text_RequestCategory: 'Request Category',
  Text_Country: 'Country',
  Table_NoRegionfound: 'No Region Found!',
  Table_NoUserfound: 'No User Found!',
  Table_NoIssueTypefound: 'No Issue Type Found!',
  Table_NoItemsFound: 'No items found!',
  Text_RequestCategoryID: 'Request Category ID',
  Text_SourceSystem: 'Source System',
  Text_EditCountries: 'Edit Countries',
  Text_AddCountries: 'Add Countries',
  Table_NoCountryFound: 'No Country Found!',
  Text_AddRootCauseCategory: 'Add Root Cause Category',
  Text_RootCauseCategoryManagement: 'Root Cause Category Management',
  Text_RootCauseCategoryID: 'Root Cause Category ID',
  Text_RootCauseCategory: 'Root Cause Category',
  Text_AddRequestCategorySourceSystemMapping: 'Add Request Category Source',
  Text_EditRequestCategorySourceSystemMapping:
        'Edit Request Category Source',
  Text_Email: 'Email',
  Text_ManagerType: 'Manager Type',
  Text_DropFileFromMachine: 'Drop file <1>browse</1> through your machine.',
  Text_SelectFile: 'Select file{{text}}',
  Text_FileMaxSize: 'Maximum file size: {{fileSize}}',
  Text_ActionMissingOwner: 'Action {{requestActionID}} is missing Owner',
  Text_ActionMissingDueDate: 'Action {{requestActionID}} is missing Due Date',
  Text_FileMissingDescription: '{{fileName}} is missing Description',
  Table_NoRequestCategoryfound: 'No Request Category Found!',
  Text_EmailID: 'E-mail ID',
  Text_SentTo: 'Sent To',
  Text_EmailSubjectKeyword: 'E-mail Subject Keyword',
  Text_RequestNumber: 'Request Number',
  Text_Location: 'Location',
  Text_EmailType: 'E-mail Type',
  Text_RequestTitleKeyword: 'Request Title Keyword',
  Text_RequestStatus: 'Request Status',
  Text_RequestManagingGroup: 'Request Managing Group',
  Text_SentBy: 'Sent By',
  Text_DateType: 'Date Type',
  Text_From: 'From',
  Text_To: 'To',
  Text_AddRiskRanking: 'Add Risk Ranking',
  Text_EditRiskRanking: 'Edit Risk Ranking',
  Text_RiskID: 'Risk ID',
  Text_EditRequest: 'Edit Request',
  Text_RiskRanking: 'Risk Rankings',
  Text_Probability: 'Probability',
  Text_Severity: 'Severity',
  Table_NoRiskfound: 'No Risk Found!',
  Table_NoEmailfound: 'No Email Found!',
  Text_No_Items: 'No items!',
  Text_NoRequestFound: 'No request found!',
  Text_NoColumsFound: 'No columns found!',
  Text_LoadingComponent: 'Loading Component',
  Header_AddObjectConfiguration: 'Add Object Configurations',
  Header_EditObjectConfiguration: 'Edit Object Configurations',
  Header_RootCauseNameManagement: 'Root Cause Name Management',
  Header_AddRootCauseSearch: 'Add Root Cause Search',
  Header_EditRootCauseSearch: 'Edit Root Cause Search',
  Header_AddRootCauseName: 'Add Root Cause Name',
  Header_EditRootCauseName: 'Edit Root Cause Name',
  Header_SearchUserViaLDAP: 'Search User Via LDAP',

  Header_RequestCategories: 'Request Categories',
  Header_AddRequestCategory: 'Add Request Category',

  Text_AddRootCauseName: 'Add Root Cause Name',
  Text_RootCauseNameSearch: 'Root Cause Name Search',
  Text_EditRootCauseName: 'Edit Root Cause Name',
  Text_RootCauseNameID: 'Root Cause Name ID',
  Text_RootCauseName: 'Root Cause Name',
  Header_AddProbability: 'Add Probability',
  Header_EditProbability: 'Edit Probability',
  Header_ProbabilityRanking: 'Probability Rankings',
  Header_SeverityRanking: 'Severity Rankings',
  Header_AddSeverity: 'Add Severity',
  Header_EditSeverity: 'Edit Severity',
  Header_CreateUser: 'Create User',
  Header_EditUser: 'Edit User',

  Info_Message_ExportSuccess: 'Export Success',
  Info_PendingAsRequestManager: 'Unapproved requests under your management.',
  Info_PendingAsRequestApprover: 'Requests awaiting your approval.',
  Info_PendingAsActionOwner: 'Pending actions on requests assigned to you ',

  Text_SeverityID: 'Severity ID',
  Text_SeverityName: 'Severity Name',
  Text_AddSeverity: 'Add Severity',
  Text_EditSeverity: 'Edit Severity',
  Text_EditRequestCategory: 'Edit Request Category',
  Text_EditRisk: 'Edit Risk',
  Text_EditRootCauseCategory: 'Edit RootCause Category',
  Text_AddRootCauses: 'Add Root Causes',
  Tooltip_UserLDAPSearch: 'Click icon to open LDAP Search',
  Tooltip_AddObjectConfiguration: 'Add Object Configuration',
  Tooltip_EditObjectConfiguration: 'Edit Object Configuration',
  Tooltip_SeverityRanking: 'iEval | Severity Rankings',
  Tooltip_AddSeverity: 'iEval | Add severity',
  Tooltip_EditSeverity: 'iEval | Edit severity',
  Tooltip_IssueTypes: 'iEval | Issue Types',
  Tooltip_AddIssueType: 'iEval | Add Issue Type',
  Tooltip_EditIssueType: 'iEval | Edit Issue Type',
  Tooltip_RootCauseCategories: 'iEval | Root Cause Categories',
  Tooltip_AddRootCauseCategory: 'iEval | Add Root Cause Category',
  Tooltip_EditRootCauseCategory: 'iEval | Edit Root Cause Category',
  Tooltip_Countries: 'iEval | Countries',
  Tooltip_AddCountry: 'iEval | Add Countries',
  Tooltip_EditCountry: 'iEval | Edit Countries',
  Tooltip_RequestCategorySourceSystemMapping:
        'iEval | Request Category Source',
  Tooltip_AddRequestCategorySourceSystemMapping:
        'Add Request Category Source',
  Tooltip_EditRequestCategorySourceSystemMapping:
        'Edit Request Category Source',
  Tooltip_SentEmails: 'iEval | Sent Emails',

  Tooltip_EmailSearch: 'iEval | Email Search',
  Tooltip_RequestCategories: 'iEval | Request Categories',
  Tooltip_AddMap: 'iEval | Add Map',
  Tooltip_EditMap: 'iEval | Edit Mapping',
  Tooltip_RiskRanking: 'iEval | Risk Rankings',
  Tooltip_AddRiskRanking: 'iEval | Add Risk Ranking',
  Tooltip_EditRiskRanking: 'iEval | Edit Risk Ranking',
  Tooltip_EditRootCauses: 'iEval | Edit Root Causes',
  Tooltip_AddRootCauses: 'iEval | Add Root Causes',

  Tooltip_AddRole: 'Add Role',
  Tooltip_EditRole: 'Edit Role',
  Tooltip_RootCauses: 'iEval | Root Causes',

  Table_NoSeverityfound: 'No Severity Found!',
  Header_Companies: 'Companies',
  Header_AddCompany: 'Add Company',
  Header_EditCompany: 'Edit Company',
  Header_MappingManagement: 'Request Workflow Management',
  Header_AddMapping: 'Add Mapping',
  Header_EditMapping: 'Edit Mapping',
  Header_RiskRanking: 'Risk Rankings',
  Header_AddRootCauses: 'Add Root Causes',
  Header_EditRootCauses: 'Edit Root Causes',
  Header_Action_Column_New: 'To do',
  Header_Action_Column_Created: 'Created',
  Header_Action_Column_Started: 'In Progress',
  Header_Action_Column_InProgress: 'In Progress',
  Header_Action_Column_Completed: 'Completed',
  Header_MyActions: 'My Actions',
  Header_Actions: 'Actions',
  Header_List: 'List',
  Header_Verification: 'Verifications',
  Header_Attachments: 'Attachments',
  Label_ListEntries: 'List Entries',
  Label_ManagedByName: 'Request Manager',
  Label_EditList: 'List Edit',
  Label_ListEntry: 'List Entry',
  Header_GeneralInfo: 'General',
  Label_Configs: 'Configs',
  Label_ObjectType: 'Object Type',
  Label_BelongsTo: 'Belongs To',
  Label_RecordID: 'Record ID',
  Label_JobStatus: 'Job Status',
  Label_SampleName: 'Sample Name',
  Label_SampleDescription: 'Sample Description',
  Label_SampleSource: 'Sample Source',
  Label_SamplingDateFrom: 'Sampling Date From',
  Label_SamplingDateTo: 'Sampling Date To',
  Label_DateCreatedFrom: 'Date Created From',
  Label_DateCreatedTo: 'Date Created To',
  Label_SampleStatus: 'Sample Status',
  Label_Client: 'Client',
  Label_Project: 'Project',
  Label_Name: 'Name',
  Label_Job: 'Job',
  Label_AvgDays: 'Average Days',
  Label_Last3Months: 'Last 3 months',
  Label_Jobs: 'Jobs',
  Label_Samples: 'Samples',
  Label_TurnaroundTime: 'Turnaround Time',
  Label_Received: 'Received',
  Label_PrepStarted: 'Prep Started',
  Label_InAnalysis: 'In Analysis',
  Label_Due: 'Due',
  Label_Reported: 'Reported',
  Label_Invoiced: 'Invoiced',
  Label_RoleName: 'Role Name',
  Label_Active: 'Active',
  Label_Display: 'Display',
  Label_Title: 'Title',
  Label_Zone: 'Zone',
  Label_DocumentType: 'Document Type',
  Label_Role: 'Role',
  Label_Roles: 'Roles',
  Label_AssignedLocations: 'Assigned Locations',
  Label_AssignedDivisions: 'Assigned Divisions',
  Label_SourceSystems: 'Source Systems',
  Label_Description: 'Description',
  Label_Summary: 'Summary',
  Label_ActionTobeimplemented: 'Action to be implemented',
  Label_CommentAction_Implemented: 'Comment/Action Implemented',
  Label_Details: 'Details',
  Label_User: 'User',
  Label_ImpersonateUser: 'Impersonate User',
  Label_Sort: 'Sort',
  Label_Direction: 'Direction',
  Label_FromAToZ: 'From A to Z',
  Label_FromZToA: 'From Z to A',
  Label_ReportName: 'Report Name',
  Label_JobNumber: 'Job Number',
  Label_DateGeneratedFrom: 'Date Generated From',
  Label_DateGeneratedTo: 'Date Generated To',
  Label_JobName: 'Job Name',
  Label_ReportType: 'Report Type',
  Label_LocationName: 'Location Name',
  Label_LocationCode: 'Location Code',
  Label_LocationType: 'Location Type',
  Label_LocationAlias: 'Location Alias',
  Label_RequestCategoryID: 'Request Category',
  Label_LocationID: 'Location',
  Label_Request_Title: 'Request Title',
  Label_RequestTitle: 'Request Title',
  Label_Request_Type: 'Request Type',
  Label_Request_Reported_By: 'Request Reported By',
  Label_RequestReportedBy: 'Request Reported By',
  Label_Probability_Ranking: 'Probability Ranking',
  Label_Severity_Ranking: 'Severity Ranking',
  Label_Region: 'Region',
  Label_RegionName: 'Region Name',
  Label_Regions: 'Regions',
  Label_Code: 'Code',
  Label_ProbabilityName: 'Probability Name',
  Label_Probability: 'Probability',
  Label_CountryName: 'Country Name',
  Label_CountryCode: 'Country Code',
  Label_Tenant: 'Tenant',
  Label_State: 'State',
  Label_City: 'City',
  Label_PostalCode: 'Postal Code',
  Label_AddressLine2: 'Address Line 2',
  Label_AddressLine1: 'Address Line 1',
  Label_SentTo: 'Sent To',
  Label_EmailSubjectKeyword: 'E-mail Subject Keyword',
  Label_EmailType: 'Email Type',
  Label_SentBy: 'Sent By',
  Label_DateType: 'Date Type',
  Label_From: 'From',
  Label_To: 'To',
  Label_Trigger: 'Trigger',
  Label_Frequency: 'Frequency',
  Label_DayCount: 'Day Count',
  Label_DayType: 'Day Type',
  Label_ScheduleType: 'Schedule Type',
  Label_RegionCode: 'Region Code',
  Label_Alias: 'Alias',
  Label_LocationAbbreviation: 'Location Abbreviation',
  Label_Timezone: 'Timezone',
  Label_UserName: 'Username',
  Label_FirstName: 'First Name',
  Label_LastName: 'Last Name',
  Label_Email: 'E-mail',
  Label_Phone: 'Phone',
  Label_RequestCategory: 'Request Category',
  Label_CustomFunctionality: 'Custom Functionality',
  Label_CustomFunction: 'Custom Function',
  Label_Functionality: 'Functionality',
  Label_JobProgress: 'Job Progress',
  Label_Add: 'Add',
  Label_RootCauses: 'Root Causes',
  Label_Workflow: 'Workflow',
  Label_Reminder: 'Reminders',
  Label_SpecialRules: 'Special Rules',
  Label_Rule: 'Rule',
  Label_ToRequestMangerUponAutoRequestcreation:
        'To Request Manger upon Auto Request creation',
  Label_ToRequestManageruponRequestManagerchange:
        'To Request Manager upon Request Manager change',
  Label_ToActionOwneruponActioncreation:
        'To Action Owner upon Action creation',
  Label_ToRequestApproveruponRequestsubmittedforApproval:
        'To Request Approver upon Request submitted for Approval',
  Label_ToRequestMangeruponRequestbeingRejected:
        'To Request Manger upon Request being Rejected',
  Label_ToRequestMangeruponRequestbeingApproved:
        'To Request Manger upon Request being Approved',
  Label_ToRequestMangeruponVerificationsbeingcreated:
        'To Request Manger upon Verifications being created',
  Label_ToVerificationOwneruponVerificationbeingassigned:
        'To Verification Owner upon Verification being assigned',
  Label_Severity: 'Severity',
  Label_SearchUser: 'Search User',
  Label_RequestDetail: 'Request Detail',
  Label_ManagerType: 'Manager Type',
  Label_DefaultAuthorizer: 'Default Authorizer',
  Label_DefaultManagingGroup: 'Default Managing Group',
  Label_ActionDateType: 'Action Date Type',
  Label_VerificationDateType: 'Verification Date Type',
  Label_Filter_Settings: 'Filter Settings',
  Label_ExportData: 'Export Data',
  Label_DateFormat: 'Date Format',
  Label_DateTimeFormat: 'Date Time Format',
  Label_SubmitForApproval: 'Submit For Approval',
  Label_MyWork: 'My Work',
  Label_RequestDateType: 'Date Type',

  Text_AddProbability: 'Add Probability',
  Text_EditProbability: 'Edit Probability',
  Text_ProbabilityID: 'Probability ID',
  Text_ProbabilityName: 'Probability Name',
  Text_ProbabilityRankings: 'Probability Rankings',
  Table_NoProbabilityfound: 'No Probability Found!',
  Text_RequestCategories: 'Request Categories',
  Text_EditRootCauses: 'Edit Root Causes',
  Text_AddRequestCategory: 'Add Request Category',
  Label_SeverityName: 'Severity Name',
  Label_ActionsToBeRejected: 'Actions to be rejected',

  Title_NewList: 'New List',
  Title_Showing_X_Records: 'Showing {{length}} record(s)',
  Title_DataExport: 'Data Export',

  Button_Save: 'Save',
  Button_SaveFilter: 'Save Filter',
  Button_SavedFilter: 'Saved Filter',
  Btn_ShowFilter: 'Show Filter',
  Btn_Beautify: 'Beautify',
  Btn_Minify: 'Minify',
  Btn_HideFilter: 'Hide Filter',
  Button_Back: 'Back',
  Button_Delete: 'Delete',
  Btn_Apply: 'Apply',
  Btn_Reset: 'Reset',
  Btn_ClearFilters: 'Clear Filters',
  Btn_ManageDisplay: 'Manage Display',
  Btn_StopImpersonating: 'Stop Impersonating',
  Btn_Logout: 'Log Out',
  Btn_Add: 'Add',
  Btn_Save: 'Save',
  Btn_Cancel: 'Cancel',
  Btn_Ok: 'Ok',
  Btn_Yes: 'Yes',
  Btn_No: 'No',
  Btn_Print: 'Print',
  Btn_Upload: 'Upload',
  Btn_RemoveAll: 'Remove All',
  Btn_NewDocument: 'Document',
  Btn_AddNews: 'News',
  Btn_Submit: 'Submit',
  Btn_Start: 'Start',
  Btn_Settings: 'Settings',
  Button_Update: 'Update',
  Btn_AddNew: 'Add New',
  Btn_Next: 'Next',
  Btn_Previous: 'Previous',
  Btn_Export: 'Export',
  Btn_ExportCSV: 'Export CSV',
  Btn_ExportAll: 'Export All',
  Btn_ChangeMyPassword: 'Change My Password',
  Btn_ExportToCSV: 'Export To CSV',
  Btn_Load5MoreLastCompletedActions: 'Load 5 more last completed actions',
  Btn_Complete: 'Complete',
  Btn_ShowMoreComments: 'More',
  Btn_Comment: 'Comment',
  Btn_ReOpen: 'Re-open',
  Btn_Profile: 'Profile',
  Btn_Approve: 'Approve',
  Btn_Reject: 'Reject',
  Btn_TextReOpen: 'Reopen',
  Btn_Cancel_Request: 'Cancel Request',
  Btn_SubmitForApproval: 'Submit For Approval',
  Btn_CreateIncident: 'Create Incident',
  Btn_AddRole: 'Add Role',
  Btn_AddUser: 'Add User',
  Btn_AddRootCause: 'Add Root Cause',
  Btn_AddRiskRanking: 'Add Risk Ranking',
  Btn_Confirm: 'Confirm',

  Column_ManagerType: 'Manager Type',
  Column_SentTo: 'Sent To',
  Column_EmailSubject: 'E-mail Subject',
  Column_SentBy: 'Sent By',
  Column_SentOn: 'Sent On',
  Column_DueDate: 'Due Date',
  Column_EmailType: 'E-mail Type',
  Column_RequestNo: 'Request Number',
  Column_RequestTitle: 'Request Title',
  Column_RequestManager: 'Request Manager',
  Column_RoleName: 'Role Name',
  Column_CreatedOn: 'Created On',
  Column_CreatedBy: 'Created By',
  Column_UserID: 'User ID',
  Column_RequestCategory: 'Request Category',
  Column_BusinessLine: 'Business Line',
  Column_BelongsTo: 'Belongs To',
  Column_RecordID: 'Record ID',
  Column_UpdatedOn: 'Updated On',
  Column_UpdatedBy: 'Updated By',
  Column_Action: 'Action',
  Column_Title: 'Title',
  Column_Filename: 'Filename',
  Column_GeneratedOn: 'Generated On',
  Column_Date: 'Date',
  Column_Headline: 'Headline',
  Column_Select: 'Select',
  Column_FirstName: 'First Name',
  Column_LastName: 'Last Name',
  Column_EmailAddress: 'Email',
  Column_Controls: 'Controls',
  Column_CountryCode: 'Country Code',
  Column_CountryName: 'Country Name',
  Column_IssueDescription: 'Issue Description',
  'Column_Action ID': 'Action ID',
  'Column_User ID': 'User ID',
  'Column_First Name': 'First Name',
  'Column_Last Name': 'Last Name',
  Column_Region: 'Region',
  Column_Email: 'Email',
  Column_ActionOwner: 'Action Owner',
  'Column_Action Title': 'Action Title',
  Column_Description: 'Description',
  Column_Type: 'Type',
  Column_Status: 'Status',
  Column_ID: 'ID',
  'Column_Request Category ID': 'Request Category ID',
  'Column_Date Type': 'Date Types',
  'Column_Due Days': 'Due Days',
  'Column_E-mail Type': 'E-Mail Types',
  'Column_Severity ID': 'Severity ID',
  'Column_Severity Name': 'Severity Name',
  'Column_Created By': 'Created By',
  'Column_Created On': 'Created On',
  'Column_Started On': 'Started On',
  'Column_Verified By': 'Verified By',
  'Column_Completed On': 'Completed On',
  Column_Comment: 'Comment',
  Column_VerificationOwner: 'Verification Owner',
  Column_VerificationID: 'Verification ID',
  Column_Details: 'Details',
  Column_Comments: 'Comments',
  Column_TargetDate: 'Target Date',
  'Column_Document Type': 'Document Type',
  'Column_File Name': 'File Name',
  'Column_Uploaded On': 'Uploaded On',
  'Column_Uploaded By': 'Uploaded By',
  'Column_Last Updated On': 'Last Updated On',
  'Column_Updated On': 'Updated On',
  'Column_Updated By': 'Updated By',
  'Column_Last Updated By': 'Last Updated By',
  'Column_Request ID': 'Request ID',
  'Column_Request Title': 'Request Title',
  'Column_Request Status': 'Request Status',
  Column_Location: 'Location',
  'Column_Due Date': 'Due Date',
  Column_PastDue: 'PastDue',
  'Column_Reminder Count': 'Reminder Count',
  'Column_Last Reminder Date': 'Last Reminder Date',
  'Column_Region ID': 'ID',
  'Column_Managed By': 'Managed By',
  'Column_Approved By': 'Approved By',
  'Column_Approved On': 'Approved On',
  'Column_Request Category': 'Request Category',
  'Column_Source System': 'Source System',
  'Column_Risk Ranking': 'Risk Ranking',
  'Column_Request Type': 'Request Type',
  'Column_Root Cause Category ID': 'Root Cause Category ID',
  'Column_Risk Rankings': 'Risk Rankings',
  'Column_Root Cause Category': 'Root Cause Category',
  Column_Probability: 'Probability',
  'Column_Issue Description': 'Issue Description',
  'Column_Company ID': 'ID',
  Column_Id: 'Id',
  'Column_Company Name': 'Company Name',
  'Column_Company Code': 'Company Code',
  'Column_Registration No.': 'Registration No',
  'Column_Registration No': 'Registration No',
  'Column_Country Code': 'Country Code',
  'Column_Country Name': 'Country Name',
  'Column_Country Names': 'Country Names',
  'Column_Probability ID': 'ID',
  'Column_Location ID': 'ID',
  'Column_Location Name': 'Location Name',
  'Column_Location Type': 'Location Type',
  'Column_Location Alias': 'Location Alias',
  'Column_Location Abbreviation': 'Location Abbreviation',
  Column_Zone: 'Zone',
  'Column_Probability Name': 'Probability Name',
  'Column_Root Cause Name': 'Root Cause Name',
  'Column_Root Cause ID': 'Root Cause ID',
  'Column_Action Status': 'Action Status',
  Column_Active: 'Active',
  'Column_Action Type': 'Action Type',
  'Column_Issue Type': 'Issue Type',
  Column_TPU: 'Parameter',
  Column_Mean: 'Mean',
  'Column_Std Dev': 'Std Dev',
  Column_LCL: 'LCL',
  Column_UCL: 'UCL',
  'Column_Lab Result': 'Lab Result',
  Column_Delta: 'Delta',
  Score: 'Score',
  ZScore: 'Z',
  JScore: 'J',
  CJScore: 'CJ',
  ENScore: 'EN',
  'Column_Z Score': 'Z Score',
  'Column_Recheck Value': 'Recheck Value',
  'Column_Test Method': 'Method',
  Column_Parameter: 'Parameter',
  'Column_Spec Comparison': 'Spec Comparison',
  Column_Result: 'Result',
  'Column_Spec Limit': 'Spec Limit',
  'Column_Result Limit': 'Result Limit',
  'Column_Reported By': 'Reported By',
  'Column_Reported Date': 'Reported Date',
  'Column_Reviewed By': 'Reviewed By',
  'Column_Reviewed On': 'Reviewed On',
  'Column_Request Date Time': 'Request Date Time',
  'Column_Request Managed By': 'Request Managed By',
  'Column_Request Approved By': 'Request Approved By',
  'Column_Action ID / Verification ID': 'Action ID / Verification ID',
  'Column_Action Date / Verification Date': 'Action Date / Verification Date',
  'Column_Action Status / Verification Status': 'Action Status / Verification Status',
  'Column_Action Owner / Verification Owner': 'Action Owner / Verification Owner',
  'Column_Action Description / Verification Description': 'Action Description / Verification Description',
  'Column_Request Manager': 'Request Manager',
  'Column_Request Approver': 'Request Approver',
  'Column_Request#': 'Request#',
  'Column_Action Id': 'Action Id',
  'Column_Request Number': 'Request Number',
  'Column_Request Title Keyword': 'Request Title Keyword',
  'Column_Action Date Type': 'Action Date Type',
  Column_VerificationStatus: 'Verification Status',
  'Column_Verification Id': 'Verification Id',
  'Column_Country of Performing Office': 'Country of Performing Office',
  'Column_Contracting Entity': 'Contracting Entity',
  'Column_Country of Contracting Entity': 'Country of Contracting Entity',
  'Column_Manager Type': 'Manager Type',
  'Column_Default Request Manager': 'Default Request Manager',
  'Column_Request Managing Group': 'Request Managing Group',

  Column_From: 'From',
  Column_To: 'To',
  'Column_Business Line': 'Business Line',
  Column_Company: 'Company',
  Column_Country: 'Country',
  Column_VerifiedOn: 'Verified On',
  'Column_Creation Mode': 'Creation Mode',
  'Column_Request Reporter': 'Request Reporter',
  Column_Findings: 'Findings',
  'Column_Performing Office': 'Performing Office',
  'Column_Reported On': 'Reported On',
  'Column_Request Action Description': 'Request Action Description',
  Column_Severity: 'Severity',
  Column_Subject: 'Subject',
  'Column_Subject Type': 'Subject Type',
  'Column_Subject Contact': 'Subject Contact',
  'Column_LTO Keeper': 'LTO Keeper',
  'Column_Contracting Office': 'Contracting Entities',
  'Column_Is Receipient Performing County': 'Is Recipient Performing County',
  'Column_Is Owner Performing Office': 'Is Owner Performing Office',
  'Column_Request Origin': 'Request Origin',
  'Column_Receipient User': 'Recipient User',
  Column_Decision: 'Decision',
  Column_Scheme: 'Scheme',
  'Column_Accreditation Body': 'Accreditation Body',
  'Column_Request Reason': 'Request Reason',
  'Column_Job Name': 'Job Name',
  'Column_Business Unit': 'Business Unit',
  Column_RequestStatus: 'Request Status',
  'Column_Due On': 'Due On',
  'Column_Past Due': 'Past Due',
  Column_Remind: 'Remind',
  Column_Required: 'Required',
  Column_Name: 'Name',
  Column_Owner: 'Owner',
  'Column_Problem Statement': 'Problem Statement',
  'Column_List Name': 'List Name',
  Column_ObjectType: 'Object Type',
  Column_IssueType: 'Issue Type',
  Column_PendingAsRequestManager: 'Pending as Request Manager',
  Column_PendingAsRequestApprover: 'Pending as Request Approver',
  Column_PendingAsActionOwner: 'Pending as Action Owner',
  Column_RootCauseName: 'Root Cause Name',
  Column_RootCauseCategory: 'Root Cause Category',
  Column_CompanyName: 'Company Name',
  Column_CompanyCode: 'Company Code',
  Column_RegistrationNo: 'Registration No',
  Column_ActionStatus: 'Action Status',
  Column_DateType: 'Date Type',
  Column_DueDays: 'Due Days',
  Column_SourceSystem: 'Source System',
  Column_LocationName: 'Location Name',
  Column_LocationAbbreviation: 'Location Abbreviation',
  Column_LocationType: 'Location Type',
  Column_LocationAlias: 'Location Alias',
  Column_ProbabilityName: 'Probability Name',
  Column_CountryNames: 'Country Names',
  Column_ActionID: 'Action ID',
  Column_AttachmentRequired: 'Attachment Required',
  Column_CommentRequired: 'Comment Required',
  Column_ActionTitle: 'Action Title',
  Column_StartedOn: 'Started On',
  Column_CompletedOn: 'Completed On',
  Column_StatusName: 'Status',
  Column_RequestCategoryName: 'Request Category',
  Column_RootCauseCategoryName: 'Root Cause Category',
  Column_ManagedBy: 'Managed By',
  Column_ApprovedBy: 'Approved By',
  Column_ApprovedOn: 'Approved On',

  Confirm_Title_ArchiveDocument: 'Archive document: ',
  Confirm_Message_ArchiveDocument:
        'Are you sure you want to archive this document?',
  Confirm_Title_UnarchiveDocument: 'Unarchive document: ',
  Confirm_Message_UnarchiveDocument:
        'Are you sure you want to unarchive this document?',
  Confirm_Message_DeleteChecklist:
        'Are you sure you want to delete this checklist?',
  Confirm_Message_StartAction: 'Are you sure you want to start this action?',
  Confirm_Message_CompleteAction: 'Are you sure you want to complete this action?',

  Tab_General: 'General',
  Tab_Notifications: 'Notifications',
  Tab_Title_AppName: 'iEvaluate',
  Tab_Profile: 'Profile',
  Tooltip_Download: 'Download',
  Tooltip_Archive: 'Archive',
  Tooltip_Unarchive: 'Unarchive',
  Tooltip_Default: 'Default',
  Tooltip_DocumentSearch: 'Access BV | Document Search',
  Tooltip_RequestSearch: 'Access BV | Request Search',
  Tooltip_ReportSearch: 'Access BV | Report Search',
  Tooltip_RootCauseSearch: 'iEval | Root Cause Search',
  Tooltip_EditRootCause: 'iEval | Edit Root Cause',
  Tooltip_AddRootCause: 'iEval | Add Root Cause',
  Tooltip_Locations: 'iEval | Locations',
  Tooltip_AddLocation: 'iEval | Add Location',
  Tooltip_EditLocation: 'iEval | Edit Location',
  Tooltip_Regions: 'iEval | Regions',
  Tooltip_AddRegion: 'iEval | Add Region',
  Tooltip_EditRegion: 'iEval | Edit Region',
  Tooltip_RootCauseNameSearch: 'iEval | Root Cause Name Search',
  Tooltip_AddRootCauseName: 'iEval | Add Root Cause Name',
  Tooltip_EditRootCauseName: 'iEval | Edit Root Cause Name',
  Tooltip_AddProbability: 'iEval | Add Probability',
  Tooltip_EditProbability: 'iEval | Edit Probability',
  Tooltip_ProbabilityRanking: 'iEval | Probability Rankings',
  Tooltip_Companies: 'iEval | Companies',
  Tooltip_AddCompany: 'iEval | Add Company',
  Tooltip_EditCompany: 'iEval | Edit Company',
  Tooltip_AddRequestCategory: 'iEval | Add Request Category',
  Tooltip_EditRequestCategory: 'iEval | Edit Request Category',
  Tooltip_MappingManagement: 'iEval | Mapping Management',
  Tooltip_AddMapping: 'iEval | Add Mapping',
  Tooltip_Editmapping: 'iEval | Edit Mapping',
  Tooltip_EditRequest: 'iEval | Edit Request',
  Tooltip_UserSearch: 'iEval | User Search',
  Tooltip_Export_To_CSV: 'Export To CSV',

  Tab_AllDocuments: 'Documents and instruction manuals',
  Tab_UserDocuments: 'User Documents',
  Tab_LatestNews: 'Latest News',
  Tab_NewsArchived: 'News Archived',

  Axis_TurnaroundTime: 'Turnaround Time (days)',

  Label_Filters: 'Filters',
  Label_RequestNumber: 'Request Number',
  Label_RequestTitleKeyword: 'Request Title Keyword',
  Label_RequestType: 'Request Type',
  Label_IssueType: 'Issue Type',
  Label_IssueDescription: 'Issue Description',
  Label_Location: 'Location',
  Label_RequestStatus: 'Request Status',
  Label_RootCauseCategory: 'Root Cause Category',
  Label_RootCauseName: 'Root Cause Name',
  Label_SourceSystem: 'Source System',
  Label_CreationMode: 'Creation Mode',
  Label_RiskRanking: 'Risk Ranking',
  Label_RequestReporter: 'Request Reporter',
  Label_RequestManagingGroup: 'Request Managing Group',
  Label_RequestApprover: 'Request Approver',
  Label_ActionOwner: 'Action Owner',
  Label_VerificationOwner: 'Verification Owner',
  Label_Company: 'Company',
  Label_Comment: 'Comment',
  Label_Comments: 'Comments',
  Label_NoComments: 'No comments',
  Label_Approved_On: 'Approved On',
  Label_Last_Updated_By: 'Last Updated By',
  Label_Last_Updated_On: 'Last Updated On',
  Label_SubjectName: 'Subject Name',
  Label_Subject_Type: 'Subject Type',
  Label_Subject_Contact: 'Subject Contact',
  Label_LTO_Keeper: 'LTO Keeper',
  Label_Performing_Office: 'Performing Office',
  Label_Contracting_Office: 'Contracting Entities',
  Label_CriticalLocationCompany: 'Contracting Entities',
  Label_Receipient_Performing_County: 'Is Recipient Performing County',
  Label_Owner_Performing_Office: 'Is Owner Performing Office',
  Label_Request_Origin: 'Request Origin',
  Label_Receipient_User: 'Recipient User',
  Label_Receipient: 'Recipient',
  Label_Request_Decision: 'Request Decision',
  Label_Scheme: 'Scheme',
  Label_Scheme_Other: 'Scheme Other',
  Label_Accreditation_Body: 'Accreditation Body',
  Label_Accreditation_Body_Other: 'Accreditation Body Other',
  Label_Request_Reason: 'Request Reason',
  Label_RequestReason: 'Request Reason',
  Label_Job_Name: 'Job Name',
  Label_Business_Unit: 'Business Unit',
  Label_Problem_Statement: 'Problem Statement',
  Label_Findings: 'Findings',
  Label_Started: 'Started',
  Label_Completed: 'Completed',
  Label_Start_Action: 'Start Action',
  Label_Complete_Action: 'Complete Action',
  Label_Object_Type: 'Object Type',
  Label_RecordId: 'Record Id',
  Label_Country: 'Country',
  Label_BusinessLine: 'Business Line',
  Label_ProductName: 'Product Name',
  Label_ClientName: 'Client Name',
  Label_Sponsor: 'Sponsor',
  Label_Program: 'Program',
  Label_Cycle: 'Cycle',
  Label_Sample: 'Sample',
  Label_Test: 'Test',
  Label_ActionId: 'Action Id',
  Label_ListName: 'List Name',
  Label_VerificationId: 'Verification Id',
  Label_ActionStatus: 'Action Status',
  Label_ActionType: 'Action Type',
  Label_ActionDate: 'Action Date',
  Label_RequestTypeDate: 'Request Type Date',
  Label_Generic: 'Generic',
  Label_FromDate: 'From Date',
  Label_FromTo: 'To Date',
  Label_Dashboard: 'Dashboard',
  Label_New: 'New',
  Label_In_Progress: 'In Progress',
  Label_Pending_Approval: 'Pending Approval',
  Label_Rejected: 'Rejected',
  Label_Created_Actions: 'Created Actions',
  Label_Started_Actions: 'Started Actions',
  Label_Almost_Past_Due: 'Almost/Past Due',
  Label_RootCauseSearch: 'Root Cause Search',
  Label_DefaultRequestManager: 'Default Request Manager',
  Label_RequestManagerGroup: 'Request Manager Group',
  Label_MappingAddTable: 'Mapping Add Table',
  Label_RequestManager: 'Request Manager',
  Label_RequestManagedBy: 'Request Manager',
  Label_SampleNumber: 'Sample Number',
  Label_Non_Conformance: 'Non Conformance Details',
  Label_Import_Non_Conformance_Data: 'Import Non Conformance Data',
  Label_Add_PTP_Details: 'Add CPMS Details',
  Label_Add_Non_Conformace_Details: 'Add Non Conformance Details',
  Label_ForgotPassword: 'Forgot Password',
  Label_NewPassword: 'New Password',
  Label_ConfirmNewPassword: 'Confirm New Password',
  Label_Countries: 'Countries',
  Label_Locations: 'Locations',
  Label_RequestCategories: 'Request Categories',
  Label_BusinessLines: 'Business Lines',
  Label_Level: 'Level',
  Label_Super: 'Super',
  Label_High: 'High',
  Label_Medium: 'Medium',
  Label_Low: 'Low',
  Label_Lowest: 'Lowest',
  Label_ActionsCompleted: 'Actions Completed',
  Label_Label: 'Label',
  Label_Create: 'Create',
  Label_Required: 'Required',
  Label_Visible: 'Visible',
  Label_View: 'View',
  Label_Edit: 'Edit',
  Label_VerificationID: 'Verification Id',
  Label_VerificationStatus: 'Verification Status',
  Label_Reject: 'Reject',
  Label_Reason: 'Reason',
  Label_CommentRequiredforcompleting: 'Comment Required',
  Label_Attachmentrequiredforcompleted: 'Attachment Required',

  Breadcrumb_RootCauseSearch: 'Root Cause Search',

  Breadcrumb_RootCauseNameManagement: 'Root Cause Name Management',
  Breadcrumb_RequestCategories: 'Request Categories',
  Breadcrumb_Locations: 'Locations',

  Table_NoRootCausefound: 'No Root Cause Found!',
  Table_NoLocationfound: 'No Location Found!',
  Breadcrumb_RootCauseCategories: 'Root Cause Categories',
  Breadcrumb_Countries: 'Countries',

  Table_NoRootCauseNamefound: 'No Root Cause Name Found!',
  Table_NoRootCauseCategoryFound: 'No Root Cause Category Found!',
  Breadcrumb_ProbabilityRanking: 'Probability Rankings',
  Breadcrumb_SeverityRanking: 'Severity Rankings',
  Breadcrumb_Companies: 'Companies',
  Breadcrumb_MappingManagment: 'Mapping Management',

  Table_NoCompanyfound: 'No Company Found!',
  Table_NorequestCategoryfound: 'No request Category found',
  Table_NoMappingfound: 'No Mapping Found!',

  Placeholder_Selectanoption: 'Select an option',
  PlaceHolder_Comments: 'Your comment here...',

  Text_RootCauseSearch: 'Root Cause Search',
  Text_AddRootCauseSearch: 'Add Root Cause Search',
  Text_EditRootCauseSearch: 'Edit Root Cause Search',
  Text_RootCauseID: 'Root Cause ID',
  Text_Showing: 'Showing',
  Text_RootCause: 'Root Cause',
  Text_LocationID: 'Location ID',
  Text_LocationName: 'Location Name',
  Text_LocationCode: 'Location Code',
  Text_LocationType: 'Location Type',
  Text_LocationAlias: 'Location Alias',
  Text_BusinessLine: 'Business Line',
  Text_Zone: 'Zone',
  Text_Company: 'Company',
  Text_AddLocation: 'Add Location',
  Text_EditLocation: 'Edit Location',
  Text_AddCompany: 'Add Company',
  Text_EditCompany: 'Edit Company',
  Text_CompanyID: 'Company ID',
  Text_CompanyName: 'Company Name',
  Text_CompanyCode: 'Company Code',
  Text_CompanyCurrency: 'Company Currency',
  Text_RegistrationNo: 'Registration No',
  Text_EditMap: 'Edit Mapping',
  Text_UserID: 'User ID',
  Text_AddMap: 'Add Mapping',
  Text_CancelRequest_Popup:
        'This will Cancel the Request and prevent further workflow. Do you want to Proceed?',
  Text_EditRole: 'Edit Role',
  Text_AddRole: 'Add Role',
  Text_Form: 'Form',

  Text_MappingID: 'Map ID',
  Text_DefaultRequestManager: 'Default Request Manager',
  Text_RequestManagerGroup: 'Request Manager Group',
  Text_CountryID: 'Country ID',
  Text_CountryCode: 'Country Code',
  Text_NoFilterFound: 'No filter found!',
  Text_NoPresetFound: 'No preset found!',
  Text_RequestIsRejected: 'Request Is Rejected',
  Text_ActivationInstruction: 'To activate the request, please use Reopen button at the bottom of the page and create new actions if necessary.',
  Text_StatusChangeConfirmation: 'Status Change Confirmation',
  Text_RejectActions: 'Select actions which will be rejected along with the request',

  Toast_CreatedSuccessfully: 'Created Successfully',
  Toast_RequestCreatedSuccessfully: 'Request is created successfully!',
  Toast_CreateFailed: 'Create Failed',
  Toast_UpdatedSuccessfully: 'Updated Successfully',
  Toast_UpdateFailed: 'Update Failed',
  Toast_CreateFailedDueToMessage:
        'Create failed due to duplicate Country Code',
  Toast_ChangeStatusSuccessfull: 'Change Status Successfull',
  Toast_FileDownloadedSuccessfully: 'File downloaded successfully!',
  Toast_RequestManagerAllowedToDelete: 'Only Request Manager is allowed to delete',
  Toast_EnterCommentForVerification: 'Enter Comments for Verification',
  Toast_ActionStatusUpdatedSuccessfully: 'Action status updated successfully!',
  Toast_Update_Action_Successfully: 'Update action successfully !',
  Toast_FileUploadedSuccessfully: 'File(s) uploaded successfully',
  Toast_FillMandatoryFields: 'Please fill all mandatory fields',
  Toast_FilterItemsDisplayUpdateFailed: 'Filter Items Display update failed!',
  Toast_FilterItemsDisplayUpdateSuccess: 'Filter Items Display updated successfully!',
  Toast_ReminderMailScheduled: 'Reminder Email Scheduled!',
  Toast_CompletedSuccessfully: 'Completed Successfully',
  Toast_NoPermissionToIncident: 'You do not have permission to create incident request',
  Toast_DownloadedSuccessfully: 'Downloaded Successfully',
  Toast_FilterDisplayUpdatedSuccessfully: 'Filter Display updated successfully!',
  Toast_FilterDisplayUpdateFailed: 'Filter Display update failed!',
  Success_ObjectConfigurationDeleted: 'Object Config Deleted',
  Success_RequestWorkflowDeleted: 'Request Workflow Deleted',

  Error_DeletingRequestWorkflow: 'Error Deleting Request Workflow',
  Error_DeletingObjectConfiguration: 'Error Deleting Object Config',
  Error_SomethingWentWrong: 'Something went wrong!',
  Error_MandatoryFieldMissing: 'Mandatory Fields Missing!',
  Error_RootCauseNameIsRequired: 'Root Cause Name is required',
  Error_MaxLengthIs100: 'Max Length is 100',
  Error_RequestCategoryIsRequired: 'Request Category is required',
  Error_LocationAliasIsRequired: 'Location Alias is required',
  Error_LocationNameIsRequired: 'Location Name is requred',
  Error_LocationCodeIsRequired: 'Location Code is required',
  Error_LocationTypeIsRequired: 'Location Type is required',
  Error_ZoneIsRequired: 'Zone is required',
  Error_CountryRegionLocationIsRequired: 'Country/Region/Location is required',
  Error_CountryIsRequired: 'Country is required',
  Error_RegionIsRequired: 'Region is required',
  Error_ObjectTypeIsRequired: 'Object Type is required',
  Error_BelongsToIsRequired: 'Belongs To is required',
  Error_RecordIDIsRequired: 'Record ID is required',
  Error_ConfigIsRequired: 'Config is required',
  Error_CompanyIsRequired: 'Company is required',
  Error_BusinessLineIsRequired: 'Business Line is required',
  Error_RootCauseCategoryIsRequired: 'Root Cause Category is Required',
  Error_ProbabilityNameIsRequired: 'Probability Name is required',
  Error_SeverityNameIsRequired: 'Severity Name is required',
  Error_CompanyNameIsRequired: 'Company Name Is Required',
  Error_IssueTypeIsRequired: 'Issue Type is required',
  Error_IssueDescriptionIsRequired: 'Issue Description is required',
  Error_AddressLine2IsRequired: 'Address Line 2 is required',
  Error_AddressLine1IsRequired: 'Address Line 1 is required',
  Error_PostalCodeIsRequired: 'Postal Code is required',
  Error_CityIsRequired: 'City is required',
  Error_StateIsRequired: 'State is required',
  Error_RiskRankingIsRequired: 'Risk ranking is required',
  Error_SourceSystemIsRequired: 'Source System is required',
  Error_RegionCodeIsRequired: 'Region Code is Required',
  Error_UsernameIsRequired: 'Username is Required',
  Error_FirstNameIsRequired: 'First Name is Required',
  Error_LastNameIsRequired: 'Last Name is Required',
  Error_EmailIsRequired: 'Email is Required',
  Error_TimezoneIsRequired: 'Timezone is Required',
  Error_EmailIsInvalid: 'Email Is Invalid',
  Error_MaxLengthIs5: 'Maximum Length is 5',
  Error_MaxLengthIs50: 'Maximum Length is 50',
  Error_RoleIsRequired: 'Role is required',
  Error_CustomFunctionalityIsRequired: 'Custom Functionality is required',
  Error_Message_ExportFailed: 'Export Failed',
  Error_DefaultRequestManagerIsRequired:
        'Default Request Manager is required',
  Error_RequestCategoryCodeIsRequired: 'Request CategoryCode is required',
  Error_RegistrationNoIsRequired: 'Registration No Is Required',
  Error_CompanyCurrencyIsRequired: 'Company Currency Is Required',
  Error_CompanyCodeIsRequired: 'Company Code Is Required',
  Error_DateTypeIsRequired: 'Date Type Is Required',
  Error_CountryCodeIsRequired: 'Country Code is required',
  Error_RequestCategoryGroupIsRequired:
        'Request Category Group is required',
  Error_SelectRequestDecisionRequired: "Select 'Request Decision' to proceed",

  Error_LocationIsRequired: 'Location Is Required',
  Error_DefaultManagerIsRequired: 'Default Manager Is Required',
  Error_ProbabilityIsRequired: 'Probability is required',
  Error_SeverityIsRequired: 'Severity is required',
  Error_RequestTitleIsRequired: 'Request Title is required',
  Error_RequestTypeIsRequired: 'Reuqest Type is required',
  Error_requestManagedBy: 'request managed by is required',
  Error_reportedOn: 'Reported on is required',
  Error_dueOn: 'Due on is required',
  Error_requestReportedBy: 'Request reported by is required',
  Error_requestApprovedBy: 'Request approved by is required',
  Error_required: 'required',
  Error_LoadingDataError: 'Loading Data Error',
  Error_RequestCategoryNameIsRequired: 'Request Category Name Is Required',
  Error_DescriptionIsRequired: 'Description is Required',
  Error_ApprovedOnIsRequired: 'Approved On is Required',
  Error_createdOn: 'Created On is Required',
  Error_ManagerTypeIsRequired: 'Manager Type is required',
  Error_RequestManagingGroupIsRequired:
        'Request Managing Group is required',
  Error_AccessLevelIsRequired: 'Access Level Is Required',
  Error_AccessLevelValueIsRequired: 'Access Level Value Is Required',
  Error_MaxLengthIs2: 'Max Length is 2',
  Error_MaxLengthIs3: 'Max Length is 3',
  Error_ScheduleIsRequired: 'Schedule Type is required',
  Error_DayCountIsRequired: 'Day Count is required',
  Error_FrequencyIsRequired: 'Frequency is required',
  Error_ActionIsRequired: 'Action is required',
  Error_TriggerIsRequired: 'Trigger is required',
  Error_DayTypeIsRequired: 'Day Type is required',
  Error_DueDaysIsRequired: 'Due Days is required',
  Error_CompleteCannotBeDone: 'Complete action cannot be done',
  Error_StartCannotBeDone:
        'This operation can only be performed by the action owner',
  Error_parameterNameIsRequired: 'Parameter Name is required',
  Error_ResultValueIsRequired: 'Result value is required',
  Error_LocationAbbreviationIsRequired: 'Location Abbreviation is required',
  Error_TPUIsRequired: 'Parameter is required',
  Error_LabResultIsRequired: 'Lab Result is Required',
  Error_TestMethodNameIsRequired: 'Method Name is required',
  Error_OnlyUpperCaseLettersAllowed: 'Only UpperCase Letters Allowed',
  Error_ActionTypeRequired: 'Action Type is required',
  Error_AssignedToRequired: 'Assigned To is required',
  Error_TitleRequired: 'Action Title is required',
  Error_ActionRequired: 'Action is required',
  Error_DueDateRequired: 'Due Date is required',
  Error_FieldIsRequired: '{{fieldName}} is required',
  Error_DetailsIsRequired: 'Details is required',
  Error_TargetDateIsRequired: 'Target Date is required',
  Error_MissingEmail: 'Email is missing!',
  Error_NoItemsFound: 'No items found!',
  Error_VerificationOwnerIsRequired: 'Verification Owner is required',

  Label_Create_Request: 'Create Request',
  Label_Home: 'Home',
  Label_Action: 'Action',
  Label_Add_Action: 'Add Action',
  Label_Edit_Action: 'Edit Action',
  Label_View_Action: 'View Action',
  Label_Close: 'Close',
  Label_Import_PTP_Data: 'Import CPMS Data',
  Label_Connect: 'Connect',
  Label_Refresh: 'Refresh',
  Label_Load: 'Load',
  Label_Attachment: 'Attachment',
  Label_AssignedTo: 'Assigned To',
  Label_ActionTitle: 'Action Title',
  Label_Drop_Your_File_Here: 'Drop your file here',
  Label_Attachment_Details: 'Add Attachment Details',
  Label_Save: 'Save',
  Label_DueDate: 'Due Date',
  Label_Audit_Trail: 'Audit Trail',
  Label_Effectiveness_Verification: 'Effectiveness Verification',
  Label_Add_Verification: 'Add Verification',
  Label_Add_Effectiveness_Verification: 'Add Effectiveness Verification',
  Label_Update_Effectiveness_Verification: 'Update Effectiveness Verification',
  Label_Heading: 'Heading',
  Label_Issue_Identification: 'Issue Identification',
  Label_Linked_Requests: 'Linked Requests',
  Label_PTP_Details: 'CPMS Details',
  Label_PTP_Change_History: 'CPMS Change History',
  Label_Review: 'Review',
  // Label_Data_Type: 'Date Type',
  Label_Reported_On: 'Reported On',
  Label_ReportedOn: 'Reported On',
  Label_Created_On: 'Created On',
  Label_Due_On: 'Due On',
  Label_DueOn: 'Due On',
  Label_CompanyName: 'Company Name',
  Label_CompanyCode: 'Company Code',
  Label_CompanyCurrency: 'Company Currency',
  Label_RegistrationNo: 'Registration No',
  Label_SearchRequests: 'Search Requests',
  Label_DragDrop: 'Drag n Drop file or click here to select',
  Label_AccessLevel: 'Access Level',
  Label_AccessLevelValue: 'Access Level Value',
  Label_SeverityEditTable: 'Severity Edit Table',
  Label_ProbabilityEditTable: 'Probability Edit Table',
  Label_Duedays: 'Due Days',
  Label_EnterReason: 'Enter Reason',
  Label_RequestCategoryCode: 'Request Category Code',
  Label_RequestCategoryName: 'Request Category Name',
  Label_ApprovedByName: 'Request Approver',
  Label_Confirm_Reject: 'Confirm Reject',
  Label_Provide_Reject_Reason: 'Provide Reject Reason',
  Label_Checklist: 'CheckList',
  Label_ObjectConfigurationinformationUpdate: 'Object Configuration Information Update',
  Label_ConfirmReload: 'Are you sure you want to delete this record?',
  Label_RequestWorkflowInformationUpdate: 'Request Workflow Information Update',
};

export default enTranslation;
